<template>

  <div class="tw-mb-40">
    <v-app-bar
        color="white"
        elevation="0"
    >
      <v-spacer></v-spacer>
      <spinner v-if="loading"></spinner>
      <v-spacer></v-spacer>
    </v-app-bar>

    <div class="tw-space-y-3">
      <div v-for="(item, i) in items" :key="i">
        <div class="tw-border tw-border-gray-n1 tw-rounded-xl tw-py-4 tw-px-6">
          <div class="tw-flex">
            <div :class="{'tw-flex tw-items-center tw-justify-center tw-bg-gray-n1 tw-rounded-lg' : !item.image}" class="tw-flex-grow-0 tw-flex-shrink-0 tw-mr-4" style="flex-basis: 247px; height: 173px">
              <img v-if="item.image" :src="item.image" alt="..." class="tw-w-full tw-h-full tw-object-center tw-object-cover tw-rounded-lg" />
              <div v-else><image-icon class="tw-w-14 tw-h-14 tw-text-gray-n2"/></div>
            </div>
            <div class="tw-flex-grow">
              <div class="tw-flex tw-justify-between">
                <div class="tw-font-semibold tw-leading-relaxed tw-mb-3">
                  <div class="tw-text-lg tw-text-black tw-mb-2">
                    {{ item.title }}
                  </div>
                </div>
                <div class="tw-flex tw-items-start tw-space-x-3 tw-mb-3">
                  <button
                      v-if="!item.is_published && false"
                      type="button"
                      v-on:click="destroy(item.id)"
                      class="tw-border tw-border-solid tw-border-red tw-rounded-xl tw-font-semibold tw-text-red tw-py-3 tw-px-8 tw-flex tw-items-center tw-leading-relaxed"
                  >
                    <x-circle-icon class="tw-w-5 tw-h-5 tw-mr-2"/>
                    Delete
                  </button>

                  <router-link
                      :to="{name: 'vendors.showcaseWork.edit', params: { id: item.id }}"
                      class="tw-border tw-border-black tw-rounded-xl tw-font-semibold tw-text-black tw-py-3 tw-px-8 tw-flex tw-items-center tw-leading-relaxed"
                  >
                    <edit2-icon class="tw-w-5 tw-h-5 tw-mr-2"/>
                    Edit
                  </router-link>

                </div>
              </div>

              <div class="tw-text-black tw-text-sm tw-leading-relaxed tw-mb-3" v-html="item.description">
              </div>

              <div class="tw-flex tw-justify-end">

                <div class="tw-text-sm tw-text-black tw-flex tw-items-center tw-space-x-3">
                  <span>{{ item.is_published ? $t('vendor.offer.published'):$t('vendor.offer.publish') }}</span>
                  <button @click="publishItem(item.id)" type="button"
                          :class="[item.is_published ? 'tw-bg-green-100' : 'tw-bg-red-100', 'tw-border-solid tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-5 tw-w-10 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none ']" role="switch" aria-checked="false">
                    <span aria-hidden="true" :class="[item.is_published ? 'tw-translate-x-5 tw-bg-green' : 'tw-translate-x-0 tw-bg-red', 'tw-pointer-events-none tw-inline-block tw-h-4 tw-w-4 tw-rounded-full tw-shadow tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200']"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading && items.length === 0">
        <div class="container">
          <v-alert
              color="#2A3B4D"
              dark
              icon="mdi-firework"
              dense
          >
            Showcasework not found. Please create one.
          </v-alert>
        </div>
      </div>

    </div>


  </div>



</template>

<script>
import {Edit2Icon, XCircleIcon, ImageIcon} from "vue-feather-icons";
import i18n from '@/i18n'

export default {
  middleware: ['auth', 'vendor', 'is-vendor-active'],
  components: {
    Edit2Icon,
    XCircleIcon,
    ImageIcon
  },
  metaInfo() {
    return {
      title:i18n.t('showcasework'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendors/showcaseWork'),
        },
      ]
    }
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  mounted() {
    this.fetchItems();
  },
  watch: {
    "$route": 'fetchItems',
  },
  methods: {
    async fetchItems() {
      this.loading = true;
      let uri = '/api/vendor/showcaseworks';
      this.$http.get(uri)
          .then((response) => {
            this.items = response.data.data;
            this.loading = false
          });
    },
    deleteItem(id) {
      let uri = '/api/vendor/showcaseworks/' + id;
      this.$http.delete(uri)
          .then(() => {
            this.fetchItems();
          });
    },
    publishItem(id) {
      let uri = '/api/vendor/showcaseworks/publish/' + id;
      this.$http.patch(uri)
          .then(() => {
            this.fetchItems();
          });
    },

    destroy(vId) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.deleteItem(vId);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
  }
}
</script>
